// Hooks
import { useAuth } from 'providers/AuthProvider';
import { useLayout } from 'providers/LayoutProvider';

// Components
import Sidebar from './Sidebar';
import SidebarToggleButton from './SidebarToggleButton';

import Logo from 'assets/svgs/Logo.svg';
import axiosInstance from 'configs/axios.config';
import cookies from 'js-cookie';
import { useState } from 'react';
import ModalWrapper from 'components/ModalWrapper';
import { RxCross2 } from 'react-icons/rx';
import { LuLogOut } from 'react-icons/lu';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { isAuthenticated, setIsAuthenticated } = useAuth();
    const { isSidebarOpened } = useLayout();
    const handleLogout = async () => {
        // const resp = await codingAxiosInstance.post(`/auth/student/logout`);
        // if (resp.status === 201) {
        const res = await axiosInstance.post('/student/auth/logout');
        if (res.status === 200) {
            // Clear all cookies
            const allCookies = cookies.get();
            for (const cookieName in allCookies) {
                cookies.remove(cookieName);
            }

            localStorage.clear();

            setIsAuthenticated(false);
        }
        // }
    };
    const [logoutScreen, setLogoutScreen] = useState(false);

    return isAuthenticated ? (
        <>
            {logoutScreen && (
                <ModalWrapper>
                    <div className=" bg-white w-[95vw] rounded-lg p-3">
                        <div className="flex justify-between items-center">
                            <div className="flex-1 text-center font-medium">
                                <p>Logout </p>
                            </div>
                            <div>
                                <RxCross2 onClick={() => setLogoutScreen(false)} />
                            </div>
                        </div>
                        <div className="mt-10 text-center">Do you really want to logout?</div>
                        <div className="mt-6 mb-3 text-center">
                            <button
                                className="w-[80%] text-lg p-3 bg-red-500 text-white rounded-full"
                                onClick={handleLogout}
                            >
                                Yes
                            </button>
                        </div>
                        <div className="text-center">
                            <button
                                onClick={() => setLogoutScreen(false)}
                                className="w-[80%] text-lg p-3 bg-slate-500 text-white rounded-full"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </ModalWrapper>
            )}

            <div className="flex fixed top-0 left-0 p-5 h-auto md:hidden w-full bg-white mb-3 items-center justify-between">
                <img src={Logo} alt="logo" className="h-10" />
                <div className="border-2 shadow-xl rounded-full p-2">
                    <LuLogOut className="h-6 w-6 " onClick={() => setLogoutScreen(true)} />
                </div>
            </div>
            <div className="h-screen w-screen mt-20 md:mt-0 flex gap-[1px] overflow-hidden">
                <div
                    className={`${
                        isSidebarOpened ? 'w-[21.5%] lg:w-[20%]' : 'w-[100px]'
                    } h-full rounded-r-md shadow-stripe px-1 lg:px-4 py-6 relative duration-100 hidden md:block`}
                >
                    <Sidebar />
                    <div className="absolute top-3 right-0 translate-x-1/2 z-40">
                        <SidebarToggleButton />
                    </div>
                </div>
                <div
                    className={`h-full overflow-y-scroll overflow-x-hidden`}
                    style={{
                        width:
                            window.screen.width < 750
                                ? '100%'
                                : isSidebarOpened
                                ? '80%'
                                : 'calc(100vw - 100px)',
                    }}
                >
                    <div>{children}</div>
                </div>
            </div>
        </>
    ) : (
        <div>{children}</div>
    );
};

export default Layout;
